import React from 'react';
import {
  createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider,
} from 'react-router-dom';
import Main from './pages/main/Main';
import Form from './pages/form/Form';
import Layout from './components/layout/Layout';
import { ToastContainer } from 'react-toastify';

import ThankYou from './pages/thankYou/ThankYou';

function getRoutes() {
  return (
    createBrowserRouter(
      createRoutesFromElements(
        <Route element={<Layout />}>
          <Route path="/" element={<Main />} />
          <Route path="/form" element={<Form />} />
          <Route path="/thank-you" element={<ThankYou />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>,
      ),
    )
  );
}

function App() {
  return (
    <div className="App">
      <ToastContainer
        limit={5}
        style={{
          maxWidth: '500px',
          width: '100%',
        }}
      />

      <RouterProvider router={getRoutes()} />
    </div>
  );
}

export default App;
