import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { Link, Outlet } from 'react-router-dom';
import LayoutStyles from './LayoutStyles';

import slogan from '../../assets/images/slogan.svg';
import logo from '../../assets/images/logo.png';
import { InView } from 'react-intersection-observer';

function Layout() {
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const { width } = useWindowSize();

  const handleMouseMove = (e: MouseEvent) => {
    const { innerWidth, innerHeight } = window;
    const xPos = (e.clientX / innerWidth) - 0.5;
    const yPos = (e.clientY / innerHeight) - 0.5;

    setOffsetX(xPos * 15);
    setOffsetY(yPos * 15);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <LayoutStyles>
      <div className="parallax-background" style={width >= 1024 ? { transform: `translate(${offsetX}px, ${offsetY}px)` } : {}} />
      <div className="parallax-content">
        <Link to="/">
          <InView as="img" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : '')} className="logo" src={logo} alt="Логотип" />
        </Link>

        <main>
          <Outlet />
        </main>

        <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : '')} className="footerWrapper">
          <footer>
            <div className="container">
              <div className="leftPart">
                <span className="text40 textLight">VELETNI</span>
                <a href="tel:+380444991894" className="text40 textLight">044-499-18-94</a>
              </div>

              <img className="slogan" src={slogan} alt="Гіганти" />

              <div className="rightPart">
                <span className="text40 textLight">ВІД ТВОРЦІВ</span>
                <a target="_blank" href="https://kozynseven.club/" className="text40 textLight" rel="noreferrer">KOZYN SEVEN CLUB</a>
              </div>
            </div>
          </footer>
        </InView>
      </div>
    </LayoutStyles>
  );
}

export default Layout;
