import React, { useEffect, useRef, useState } from 'react';
import FormStyles from './FormStyles';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import slogan from '../../assets/images/slogan.svg';
import { send } from 'emailjs-com';
import { Flip, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Form() {
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    onSubmit: async (values) => {
      if (values) {
        formik.setSubmitting(true);

        await send(
          'service_5u5susg',
          'template_10kjjis',
          values,
          'ZXkGZgvxDhfK-aRx_',
        )
          .then((response) => {
            setIsSubmitted(true);

            timeoutRef.current = setTimeout(() => {
              navigate('/');
            }, 2000);
          })
          .catch((err) => {
            toast.error('Сталася помилка під час надсилання форми', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
              transition: Flip,
            });
          });

        formik.setSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Неправильний формат емейлу')
        .required('Обов\'язкове поле'),
      name: Yup.string().required('Обов\'язкове поле'),
      phone: Yup.string()
        .matches(
          /^\+?3?8?(0\d{9})$/,
          'Неправильний формат телефону',
        )
        .required('Обов\'язкове поле'),
    }),
  });

  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  return (
    <FormStyles>
      {isSubmitted ? <div className="thankYouWrapper"><span className="text40-28 thankYou">Дякуємо</span></div> : (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className={`formFieldsWrapper ${isSubmitted ? 'submitted' : ''}`}>

              <div className="formField">
                <input
                  className="text40"
                  placeholder="ІМ’Я"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  name="name"
                  onBlur={formik.handleBlur}
                  disabled={isSubmitted}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="error">{formik.errors.name}</div>
                ) : null}
              </div>

              <div className="formField">
                <input
                  className="text40"
                  placeholder="ТЕЛЕФОН"
                  type="text"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  name="phone"
                  onBlur={formik.handleBlur}
                  disabled={isSubmitted}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="error">{formik.errors.phone}</div>
                ) : null}
              </div>

              <div className="formField">
                <input
                  className="text40"
                  placeholder="ПОШТА"
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  name="email"
                  onBlur={formik.handleBlur}
                  disabled={isSubmitted}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            <div className="sloganWrapper">
              <img className="slogan" src={slogan} alt="Гіганти" />
            </div>

            <div className="buttonWrapper">
              <div />
              <button
                disabled={isSubmitted || formik.isSubmitting}
                className="button buttonTransparent text40"
                type="submit"
                onClick={() => formik.handleSubmit()}
              >
                Відправити
              </button>
              <div />
            </div>

            <div className="formFooter" />
          </form>
        </FormikProvider>
      )}
    </FormStyles>
  );
}

export default Form;
