import styled from 'styled-components';

const MainStyles = styled.div`
  display: flex;
  align-items: center;
  
  height: 100%;
  
  .textBlockTexts {
    display: flex;
    flex-direction: column;
    
    text-align: center;
  }
  
  .topTextBlockWrapper, .textsWrapper, .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  @media (min-width: 1024px) {
    .textsWrapper, .contentWrapper {
      gap: 40px;
      
      @media (max-height: 1024px) {
        gap: 20px;
      }
    }
  }
  
  .splitter {
    height: 60px;
    width: 1px;
    margin: auto;
    
    background-color: var(--text-hover);
  }

  .dot {
    width: 10px;
    height: 10px;
    margin: 0 auto 0 auto;
    
    border-radius: 100%;
    background: var(--text-hover);
    
    opacity: 0;
    
    &.dotDesktop {
      display: none;
    }
    
    @media (min-width: 768px) {
      &.dotDesktop {
        width: 15px;
        height: 15px;

        display: block;
        transition: 1s ease opacity;

        &.inView {
          opacity: 1;
        }
      }
    }
  }
  
  .buttonWrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .splitterWrapper2 {
    @media (min-width: 768px) {
      display: none;
    }
  }
  
  .button {
    opacity: 0;
    transition: 1s ease opacity, .3s ease box-shadow;
    
    padding-right: 60px;
    padding-left: 60px;
    
    &:hover {
      box-shadow: 0 0 20px 0 rgba(255, 0, 0, 0.8);
    }
    
    &.inView {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .textsWrapper {
      .textBlock {
        opacity: 0;
        transform: translateY(30%);
        
        transition: .3s ease transform, .3s ease opacity;
      }
      
      .dot {
        opacity: 0;
        transition: .3s ease opacity;
        transition-delay: 1.5s;
      }
      
      .textBlock2 {
        transition-delay: .6s;
      }
      
      .textBlock3 {
        transition-delay: 1.2s;
      }
      
      .splitterWrapper {
        height: 60px;
      }
      
      .splitter {
        opacity: 0;
        height: 0;

        transition: .3s ease height, .3s ease opacity;
      }
      
      .splitter1 {
        transition-delay: .3s;
      }

      .splitter2 {
        transition-delay: .9s;
      }

      &.inView {
        .textBlock {
          transform: translate(0);
          opacity: 1;
        }
        
        .splitter {
          height: 60px;
          opacity: 1;
        }
        
        .dot {
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .dotMobile {
      display: none;
    }
    
    .contentWrapper {
      flex-grow: 1;
      justify-content: space-around;
      height: 100%;
    }
    
    .textsWrapper {
      gap: 0;
    }

    .topTextBlockWrapper {
      flex-direction: row;
      align-items: center;
      gap: 0px;

      .splitterWrapper {
        height: 160px;
        
        @media (min-height: 1024px) {
          height: 260px;
        }

        .splitter {
          height: 100%;
        }
      }

      .textBlock1 {
        padding-right: 40px;

        .textBlockTexts {
          text-align: right;

          transform: translateX(calc(100% + 40px));
          opacity: 0;
        }
      }

      .textBlock2 {
        padding-left: 40px;

        .textBlockTexts {
          text-align: left;

          transform: translateX(calc(-100% + 40px));
          opacity: 0;
        }
      }
    }

    .textBlockTexts {
      transition: .6s ease transform, .6s ease opacity;
    }
    
    .textBlock3 {
      padding-top: 40px;
      
      .textBlockTexts {
        text-align: center;

        transform: translateY(calc(-100% + 40px));
        opacity: 0;
      }
    }

    .textBlock {
      overflow: hidden;

      &.inView {
        .textBlockTexts {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }
  }
`;

export default MainStyles;
