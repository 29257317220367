import React from 'react';
import ThankYouStyles from './ThankYouStyles';
import { useNavigate } from 'react-router-dom';

function ThankYou() {
  return (
    <ThankYouStyles>
      <span className="text40-28">
        Дякуємо
        <br />
        Ми з Вами зв&apos;яжемось
      </span>
    </ThankYouStyles>
  );
}

export default ThankYou;
