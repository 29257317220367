import styled, { keyframes } from 'styled-components';

const showAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const FormStyles = styled.div`
  display: flex;
  align-items: center;
  
  height: 100%;
  
  animation: 1s ease ${showAnimation};
  
  input {
    border: none;
    border-bottom: 1px solid #8C8687;
    color: #000;
    width: 100%;
    outline: none;
    
    transition: .3s ease border-bottom-color;

    font-family: "Attentica 4F", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
    &:hover, &:active {
      border-bottom-color: var(--text-hover);
    }
    
    &:disabled {
      background-color: inherit;
    }
    
    &::placeholder {
      color: #8C8687;

      font-family: "Attentica 4F", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    }
  }

  .error {
    color: red;
    font-size: 16px;
    margin-top: 5px;
    text-align: left;
  }
  
  .thankYouWrapper {
    animation: 1s ease showAnimation;
  }
  
  form {
    max-width: 340px;
    width: 100%;

    .formFieldsWrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;

      padding: 80px 56px 40px;

      background-color: #FFF;
      
      &.submitted {
        padding: 40px 56px 40px;
      }
      
      @media (max-height: 1024px) {
        padding: 36px 56px 20px;
        
        &.submitted {
          padding: 20px 56px 20px;
        }
      }
    }

    .formFooter {
      height: 40px;
      background-color: #FFF;
    }
    
    .sloganWrapper {
      width: 100%;
      background-color: #FFF;
      padding-bottom: 40px;
      
      @media (min-width: 1024px) and (max-height: 1024px) {
        padding-bottom: 20px;
      }
    }

    .buttonWrapper {
      display: flex;

      .button {
        max-width: 70%;
        width: 100%;
        
        transition: .3s ease background-color, .3s ease box-shadow;
        
        &:hover {
          background-color: var(--text-hover);
        }
      }

      div {
        flex-grow: 1;
        background-color: #FFF;
      }
    }
  }
`;

export default FormStyles;
