import styled, { keyframes } from 'styled-components';

import bodyBackground from '../../assets/images/background.jpg';

const showAnimation = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

const LayoutStyles = styled.div`
  .parallax-background {
    position: fixed;
    top: -5vh;
    left: -5vw;
    width: 110vw;
    height: 110vh;
    background-image: url(${bodyBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    transition: transform 0.1s ease-out;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.05);
      z-index: -1;
    }
  }

  .parallax-content {
    position: relative;
    color: #fff;
    text-align: center;
    z-index: 1;
    height: 100vh;
    height: 100svh;
    padding: 23px 0 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    overflow: auto;

    @media (min-width: 1024px) {
      padding: 80px 0 43px;
      gap: 40px;
      
      @media (max-height: 1024px) {
        padding: 25px 0 25px;
        gap: 20px;
      }
    }
  }

  .logo {
    width: auto;
    height: 170px;
    
    opacity: 0;
    transform: scale(0.9);
    
    transition: .3s ease transform, 1s ease opacity;
    
    &.inView {
      opacity: 1;
      transform: scale(1);
    }

    @media (min-width: 1024px) {
      height: 251px;
    }
    
    @media (min-width: 1024px) and (max-height: 1024px) {
      height: 151px;
    } 
  }

  main {
    flex-grow: 1;
    height: 100%;
  }

  .footerWrapper {
    width: 100%;
  }
  
  footer {
    position: relative;
    width: 100%;

    animation: 1s ease ${showAnimation};

    .slogan {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    
    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    a {
      transition: .3s ease color;

      &:hover {
        color: var(--text-hover);
      }
    }

    .leftPart, .rightPart {
      display: flex;
      flex-direction: column;
    }

    .leftPart {
      text-align: left;
    }

    .rightPart {
      text-align: right;
    }
  }
  
  .slogan {
    height: 32px;

    @media (min-width: 1024px) {
      height: 72px;
    }
  }
`;

export default LayoutStyles;
